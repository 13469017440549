import React from "react";
class BuyBook extends React.Component {
    buybook = () => {
        window.open('https://www.amazon.in/Vrajamandal-Darshan-Hindi-Lokanath-Swami/dp/9352673077', '_blank');
    }
    
    render(){
        return(
            <div>
                <h1 className="title">Buy Book</h1>
                <div className="book-flex container">
                    <img src="https://images-eu.ssl-images-amazon.com/images/I/51pfdwBR7NL._AC_UL600_SR600,600_.jpg" alt="" />
                    <p>Vraja-maṇḍala Darśana has thirty chapters, one for each day of the month long parikramā of the forests of Vrindavan taken during the month of Kartika. It is a meditation upon the parikrama experience – the pace, sights, sounds and smells of Vraja, the morning and evening programs, kirtanas and prasada – all peppered with realizations from the pilgrims in the field. Furthermore, it contains descriptions and narrations on places of Krishna’s pastimes on the parikrama route, along with references to the writings of A. C. Bhaktivedanta Swami Śrīla Prabhupāda and previous ācāryas of the Gaudiya Vaishnava traditions. It includes route maps of places of significance in and around Vrindavan area. It attempts to help the reader deepen their appreciation not just of the Lord’s pastimes but of the Gauḍīya paramparā’s contribution and service to Vraja-dhāma. It would help those unable to travel to Vṛndāvana for the Vraja-maṇḍala parikramā to use this book to become connected by mind and heart to the parikramā party. Vraja-maṇḍala Darśana is written like a thirty-day journal, so one can read a chapter a day as part of their Kārttika sādhana no matter where they might be in the world. It aims to inspire the reader to join the parikramā during Kartika and facilitate their absorption in Vraja-dhāma and Krishna Lila.</p>
                </div>
                <button onClick={this.buybook} className="btn btn-warning book-btn">Buy Now</button>
            </div>
        )
    }
}

export default BuyBook