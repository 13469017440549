import React from "react";

import Nav from "./Nav";
import Footer from "./Footer";
import Home from "../pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Gallery from "../pages/Gallery";
import About from "../components/Home/About";
import Booking from "../pages/Booking";
import Contact from "../pages/Contact";
import News from "../pages/News";
class Routing extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Nav />
        <Routes>
          <Route path="/" element={<Home />} />  
          <Route path="/gallery" element={<Gallery />} />  
          <Route path="/about" element={<About />} />  
          <Route path="/booking" element={<Booking />} />  
          <Route path="/contact" element={<Contact />} />  
          <Route path="/News" element={<News />} />  
        </Routes>
        <Footer />
      </BrowserRouter>
    );
  }
}
export default Routing;
