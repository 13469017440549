import React from "react";
import { Link } from "react-router-dom";
import Logo from "../VarjMadalMedia/logo/Logo.png";
class Footer extends React.Component {
  render() {
    return (
        <>
      <footer>
        <div className="footer-flex container">
          <div className="social-media">
            <div>
              <Link to="https://www.facebook.com/p/Vraj-Mandal-Parikrama-100063512269428/">
                <i class="fa fa-facebook"></i>
              </Link>
              <Link to="#">
                <i className="fa fa-twitter"></i>
              </Link>
              <Link to="#">
                <i className="fa fa-instagram"></i>
              </Link>
              <Link to="https://www.youtube.com/@VrajmandalParikrama#">
                <i className="fa fa-youtube"></i>
              </Link>
            </div>
            <div className="logoinfo">
              <img src={Logo} alt="" />
              <h6>
                Vraj Mandal Parikrama, a 19th-century Indian holy mantra, is a
                spiritual practice that embodies the principles of
                self-realization
              </h6>
            </div>
          </div>
          <div className="useful-link">
            <h5>Important Links</h5>
            <Link to="#">Home</Link>
            <Link to="#">About</Link>
            <Link to="#">Contact</Link>
            <Link to="#">Booking</Link>
          </div>
          <div className="useful-link">
            <h5>Subscribe</h5>

            <div class="form-group">
              <label className="mb-2" for="exampleInputPassword1">Enter Your Email</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputPassword1"
                placeholder="Email address"
              />
            </div>
        
            <button className="btn btn-danger mt-5"> Subscribe</button>
          </div>
        </div>
      </footer>
      <div className="bottom">
        <p className="mx-5">Copyright &copy; 2024-2025 ISKCON ARAVADE. All rights reserved.  || Developed By Standard Security Solutions - 7620075105</p>
      </div>
        </>
    );
  }
}

export default Footer;
