import React from "react";

class Contact extends React.Component {
  render() {
    return (
      <div >
        <h1 className="title">Contact</h1>
        <div className="contact-home container">
          <form>
            <div class="form-group">
              <div class="form-group">
                <label for="exampleInputPassword1">Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="Your Good Name"
                />
              </div>
              <label for="exampleInputEmail1">Email address</label>
              <input
                type="email"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter email"
              />
            </div>
            <div class="form-group">
              <label for="exampleFormControlTextarea1">
                Enter Your Message
              </label>
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
              ></textarea>
            </div>
            <br />
            <button type="submit" class="btn btn-warning">
              Send Message
            </button>
          </form>
          <div className="mt-4">
            <h4 className="contact-info">Contact Information</h4>
            <br />
            <p>
              Email: <a href="mailto:varajmandalparikrama@gmail.com">varajmandalparikrama@gmail.com</a>
            </p>
            <p>Phone: +91 93253 66546</p>
            <p>Whatsapp: +91 93253 66546</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
