import React from "react";
import { Link } from "react-router-dom";
import "../style/main.css";
import "../style/mobile.css";
import Logo from '../VarjMadalMedia/logo/Logo.png'
class Nav extends React.Component {
  donation = () => {
    window.open('https://link.upilink.in/9415113744@sbi', '_blank')
  }
  render() {
    return (
      <nav className="navbar12">
        <ul>
            <li>
                <img src={Logo} alt="Logo" className="nav-logo"/>
            </li>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/booking">Booking</Link>
          </li>
          <li>
            <Link to="/news">News</Link>
          </li>
          <li>
            <Link to="/gallery">Gallery</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li>
          <div id="google_translate_element" className="translator"></div>
          </li>
          <div className="nav-button">
            <button className="btn btn-warning">Login</button>
            <button onClick={this.donation} className="btn btn-danger">Donate</button>
          </div>
        </ul>
      </nav>
    );
  }
}

export default Nav;
