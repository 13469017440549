import React from "react";

class Booking extends React.Component {
    render() {
        return(
            <div className="mt-5 pt-5 booking" >
                <iframe width="640px" height="480px" src="https://forms.office.com/r/LrsAmVhA4L?embed=true" frameborder="0" marginwidth="0" marginheight="0" allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe>
            </div>
        )
    }
}
export default Booking