import React from "react";
import AboutImage from "../../VarjMadalMedia/About/AboutBanner.jpg";
class About extends React.Component {
  render() {
    return (
      <div className="about-home container ">
        <h1 className="title">About - Vraj Mandal Parikrama</h1>
        <div className="about-home-flex mt-5">
          <img src={AboutImage} alt="" />
          <div>
            <p>
              Inscription Form, for the devotees who are going to join the Vraja
              Mandal Parikram 2024 Vraj Mandal Parikrama covers parts of Uttar
              Pradesh and Rajasthan, spanning 84 kilometers (168 miles) around
              the city of Mathura. This sacred journey traces the pastime places
              of Sri Sri Radha Krishna and their associates. Whenever “Svayam
              Bhagavan” Sri Krishna descends to this material plane, He brings
              His abode, His associates, and other paraphernalia along with Him.
              Therefore, complete this form to register and become part of this
              divine pilgrimage, immersing yourself in the holy sites and
              spiritual experiences that Vraj Mandal Parikrama offers.
            </p>
            <p>
              Vraj Mandal Parikrama is a sacred pilgrimage that involves
              circumambulating the Vraj region, an area steeped in the divine
              pastimes of Lord Krishna. This spiritual journey covers various
              towns and villages, including Vrindavan, Mathura, Govardhan, and
              Barsana, each of which is associated with different leelas
              (pastimes) of Krishna. The Parikrama is undertaken by devotees
              seeking to deepen their connection with Lord Krishna and to
              experience the spiritual energy of these holy sites. By visiting
              places like Vrindavan, known for its numerous temples, Mathura,
              the birthplace of Krishna, Govardhan, famous for the hill Krishna
              lifted to protect villagers, and Barsana, the birthplace of Radha,
              Krishna's beloved, devotees aim to attain spiritual purification
              and divine blessings.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
