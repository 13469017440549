import React from "react";

class News extends React.Component {
  constructor() {
    super();
    this.state = {
      news: 'first',
    };
  }
  handlenewsChnage = (news) => {
    this.setState({ news: news });
  };

  render() {
    const { news } = this.state;
    return (
      <div className="news-container container mt-5 pt-5">
        <h1 className="title">News</h1>
        <div className="news">
          <div className="news-section">
            <div
              className="show-news"
              onClick={() => this.handlenewsChnage("first")}
            >
              <h2>Varj Mandal Parikrama 2024</h2>
              <p>Parikrama Schedule Declared</p>
              <span className="news-date">15 July 2024</span>
              <br />
              <br />
              <button className="btn btn-danger news-btn">Show Details</button>
            </div>
            <div
              className="show-news"
              onClick={() => this.handlenewsChnage("second")}
            >
              <h2>Varj Mandal Parikrama 2023</h2>
              <p>Parikrama Schedule Declared</p>
              <span className="news-date">15 July 2024</span>
              <br />
              <br />
              <button className="btn btn-danger news-btn">Show Details</button>
            </div>
          </div>
          <div className="border-news"></div>
          <div className="show-more">
            {news === "first" && (
              <>
                <h4>Welcome at Vraj Mandal Parikrama 2024.</h4>
                <h6>Dates as Follow</h6>
                <div>
                  <table>
                    <tr>
                      <th>Event</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                    </tr>
                    <tr>
                        <td>Registration's</td>
                        <td>17th July 2024</td>
                        <td>1st October 2024</td>
                    </tr>
                    <tr>
                        <td>Parikrama Dates</td>
                        <td>17th October 2024</td>
                        <td>15th November 2024</td>
                    </tr>
                  </table>
                </div>
              </>
            )}
            {news === "second" && (
              <>
                <h4>Welcome at Vraj Mandal Parikrama 2023.</h4>
                <div>
                  <table>
                    <tr>
                    <th>Event</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                    </tr>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default News;
