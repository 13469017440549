import React from "react";
import LoknathSwami from "../../VarjMadalMedia/Mangement/LNS.jpg";
import GopalKrishna from "../../VarjMadalMedia/Mangement/GopalKrishna.jpg";
import RadhaRaman from "../../VarjMadalMedia/Mangement/RadhaRaman.jpg";
import Brajbhummi from "../../VarjMadalMedia/Mangement/Brajbhummi.jpeg";
import Istadev from "../../VarjMadalMedia/Mangement/Istadev.jpeg";

class Management extends React.Component {
  render() {
    return (
      <div className="management-board container-fluid">
        <h1 className="title">Management Board</h1>
        <div className="management-board-flex">
          <div>
            <img src={LoknathSwami} alt="" />
            <h5>H.H. Lokanath Swami Maharaj</h5>
            <p>Chairman, Vraj Mandal Parikrama</p>
          </div>
          {/* <div>
            <img src={GopalKrishna} alt="" />
            <h5>H.H. Gopal Krishna Goswami Maharaj</h5>
            <p>GBC, Vraj Mandal Parikrama</p>
          </div> */}
          <div>
            <img src={RadhaRaman} alt="" />
            <h5>H.H. RadhaRaman Swami Maharaj</h5>
            <p>Co-ordinator, Vraj Mandal Parikrama</p>
          </div>
          <div>
            <img src={Brajbhummi} alt="" />
            <h5>H.G. Brajbhumi Prabhuji</h5>
            <p>Executive Co-ordinator</p>
          </div>
          <div>
            <img src={Istadev} alt="" />
            <h5>H.G. Ishtadev Prabhuji</h5>
            <p>Parikrama Commander</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Management;
