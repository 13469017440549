import React, { useState, useEffect, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

const imagesJsonPath = "./images.json"; // Path to your images JSON

function PhotoGallery() {
  const [photos, setPhotos] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState("HomeGallery");
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    fetchPhotos();
  }, [selectedFolder]);

  const fetchPhotos = () => {
    fetch(imagesJsonPath)
      .then((response) => response.json())
      .then((data) => {
        const photoArray = [];
        const folderNames = new Set();
        Object.keys(data).forEach((folder) => {
          folderNames.add(folder);
          if (selectedFolder === "All" || selectedFolder === folder) {
            data[folder].forEach((image, index) => {
              photoArray.push({
                src: image,
                width: 4,
                height: 3,
                alt: folder,
              });
            });
          }
        });
        setPhotos(photoArray);
      })
      .catch((error) => console.error("Error fetching images:", error));
  };

  const handleFolderChange = (folder) => {
    setSelectedFolder(folder);
  };

  const openLightbox = useCallback((index) => {
    setCurrentIndex(index);
    setIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setIsOpen(false);
  };

  return (
    <div className="container-fluid">
      <h1 className="title">Photo Gallery</h1>
      <Gallery
        photos={photos}
        onClick={(event, { index }) => openLightbox(index)}
      />
      <ModalGateway>
        {isOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentIndex}
              views={photos.map((photo) => ({
                ...photo,
                srcSet: photo.srcSet,
                caption: photo.alt,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      <button className="btn btn-warning my-5 m-auto d-block">
        Explore More
      </button>
    </div>
  );
}

export default PhotoGallery;
