import React from "react";
import SliderImage from '../../VarjMadalMedia/Banner/HomeBaner.jpeg'
class SliderHome extends React.Component {
    render(){
        return(
            <div className="slider-home">
                <div className="slide">
                    <img src={SliderImage} alt="RadhaGovind"/>
                    <div className="InSlider">
                        <h2 className="text-center title">ISKCON - Varj Mandal Parikrama </h2>
                        <p>Vraj Mandal Parikrama, a 19th-century Indian holy mantra, is a spiritual practice that embodies the principles of self-realization, spiritual growth, and the pursuit of truth. It was first recited by the Hindu deity Vraj in the 14th century and has since become a popular form of spiritual practice in various religious communities across the world.</p>
                    </div>
                </div>
            </div>
        )
    }
}
export default SliderHome