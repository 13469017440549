import React from "react";
import SliderHome from "../components/Home/SliderMain";
import About from "../components/Home/About";
import Management from "../components/Home/Mangement";
import News from "../components/Home/News";
import Gallery from "../components/Home/Gallery";
import BuyBook from "../components/Home/BuyBook";
import Contact from "../components/Home/Contact";
import PopUp from "../components/PopUP";
import PopImage from '../VarjMadalMedia/Banner/mainpop.jpeg'
class Home extends React.Component {
    constructor () {
        super()
        this.state = {
            showPopup: true,
        }
    }
    togglePopup = () => {
        this.setState({ showPopup: !this.state.showPopup });
    }
    render(){
        return(
            
            <>
            {this.state.showPopup ? 
                    <PopUp 
                        closePopup={this.togglePopup}
                        image={PopImage} // Replace with your image URL
                    />
                    : null
                }
            <SliderHome/>
            <About/>
            <Management/>
            <News/>
            <Gallery/>
            <BuyBook/>
            <Contact/>
            </>
        )
    }
}

export default Home;