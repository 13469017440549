import React from 'react';
import './PopUp.css'; // Import the CSS file for styles

class PopUp extends React.Component {
    booking = () => {
        window.location.href = '/booking'
    }
    render() {
        return (
            <div className="popup">
                <div className="popup-inner">
                    <button className="close-btn" onClick={this.props.closePopup}>X</button>
                    <img src={this.props.image} alt="Popup" className="popup-image"/>
                    <button onClick={this.booking} className='btn btn-danger mt-2'>Register Now</button>
                </div>
            </div>
        );
    }
}

export default PopUp;
